import React, { useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import {
  FifthSection,
  FirstSection,
  FourthSection,
  SecondSection,
  SixthSection,
} from "@components/events/winter-wonderland/index";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { mq } from "@utils/media-query/mediaQuery";
import { Link } from "react-router-dom";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(({ theme, isJapanese }) => ({
  position: "relative",
  backgroundImage: `url(${AWS_BUCKET_URL}/1.jpg)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% auto",
  backgroundColor: "#6e8eb4",
  width: "100%",
  maxWidth: "144rem",
  margin: "0 auto",
  padding: "80% 1.5rem 1.5rem",
  fontFamily: theme.fontFamily.primaryFont,
  whiteSpace: isJapanese ? "keep-all" : "break-spaces",

  [mq("tablet")]: {
    padding: "80% 4.4rem 4.4rem",
  },

  [mq("desktop")]: {
    padding: "110rem 7.5rem 7.5rem",
  },

  "@media (min-width: 1200px) and (max-width: 1440px)": {
    maxWidth: "120rem",
    padding: "90rem 4.4rem 4.4rem",
  },
}));

const Wrapper = styled.div(() => ({
  position: "relative",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem 0",

  [mq("tablet")]: {
    gap: "3rem 0",
  },

  [mq("desktop")]: {
    gap: "5rem 0",
  },

  "@media (min-width: 1200px) and (max-width: 1440px)": {
    gap: "4.2rem 0",
  },
}));

// Common Styling
export const SectionWrapper = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "2rem",

  [mq("tablet")]: {
    padding: "3rem",
  },

  [mq("desktop")]: {
    padding: "5rem",
  },
}));

export const SectionTitle = styled.h2(() => ({
  marginBottom: "0.5rem",
  fontSize: isJapanese ? "2rem" : "2.4rem",
  fontWeight: 300,

  [mq("desktop")]: {
    fontSize: "3.2rem",
  },
}));

export const EventGridBox = styled.div(({ column = "2", gap }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  gap: gap ? gap : "1.5rem",

  [mq("tablet")]: {
    gridTemplateColumns: `repeat(${column}, minmax(200px, 1fr))`,
    gap: gap ? gap : "1.4rem",
  },

  [mq("desktop")]: {
    gridTemplateColumns: `repeat(${column}, 1fr)`,
    gap: gap ? gap : "2.5rem",
  },
}));

const ViewMoreButtonStyling = styled(Link)(({ theme, primary }) => ({
  display: "inline-block",
  padding: "0.6rem 2rem",
  border: "2px solid #68507C",
  fontWeight: 700,
  textDecoration: "none",
  cursor: "pointer",
  width: "fit-content",
  fontSize: "1.4rem",
  background: primary ? "#68507C" : theme.color.white,
  color: primary ? theme.color.white : "#68507C",

  [mq("desktop")]: {
    padding: "1rem 4rem",
    fontSize: "1.6rem",
  },
}));

export const RGUEST_URL =
  "https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort";
export const EventButton = ({
  link,
  buttonName,
  primary,
  customLink,
  rguestLink,
  outerLink,
}) => {
  const intl = useIntl();
  const intlFormat = (id) => {
    return intl.formatMessage({
      id: id,
    });
  };
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  let agilysysLang;

  switch (lang) {
    case "zh-hans":
      agilysysLang = "ZH-CN";
      break;
    case "zh-hant":
      agilysysLang = "ZH-TW";
      break;
    default:
      agilysysLang = lang?.toUpperCase();
      break;
  }

  const linkUrl = useMemo(() => {
    if (customLink) {
      return customLink;
    }

    if (rguestLink) {
      return `${RGUEST_URL}/${rguestLink}?langCode=${agilysysLang}`;
    }

    if (link) {
      return `/${lang}${link}`;
    }
  }, [agilysysLang, customLink, lang, link, rguestLink]);

  const linkToOuterSite = useCallback(() => {
    if (outerLink) {
      window.open(linkUrl, "_blank", "noopener, noreferrer");

      return;
    }

    if (rguestLink || customLink) {
      window.location.href = linkUrl;
    }
  }, [customLink, linkUrl, outerLink, rguestLink]);

  return (
    <ViewMoreButtonStyling
      to={link ? linkUrl : ""}
      primary={primary}
      onClick={linkToOuterSite}
    >
      {buttonName ? buttonName : intlFormat("lang-view-more")}
    </ViewMoreButtonStyling>
  );
};

export const AWS_BUCKET_URL =
  "https://webdevfiles.s3.ap-northeast-2.amazonaws.com/ITDA/event";

const WinterWonderland = () => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const intl = useIntl();
  const intlFormat = (id) => {
    return intl.formatMessage({
      id: id,
    });
  };

  return (
    <Container isJapanese={lang === "ja"}>
      <Wrapper>
        {/* * Welcome to INSPIRE WINTER WONDERLAND */}
        <FirstSection intlFormat={intlFormat} />

        {/* * Special Offers */}
        <SecondSection intlFormat={intlFormat} />

        {/* * Splash Bay is back*/}
        {/* TODO: uncomment when splashbay is ready */}
        {/* <ThirdSection intlFormat={intlFormat} /> */}

        {/* * Endless Culinary Journey */}
        <FourthSection intlFormat={intlFormat} />

        {/* * Momentum */}
        <FifthSection intlFormat={intlFormat} />

        {/* * Arena Performance */}
        <SixthSection intlFormat={intlFormat} />

        {/* * New Year Wish Comment Event */}

        {/*{lang === "ko" && <SeventhSection intlFormat={intlFormat} />}*/}
      </Wrapper>
    </Container>
  );
};

export default WinterWonderland;
