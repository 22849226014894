export const GA_TITLE_PATHS = [
  { path: "", title: "home" },
  { path: "/", title: "home" },
  { path: "/offers", title: "home>offers" },
  { path: "/offers?category=room", title: "home>offers>room" },
  { path: "/offers?category=dining", title: "home>offers>dining" },
  {
    path: "/offers?category=Momentum%20Lifestyle%20Events",
    title: "home>offers>momentum",
  },
  {
    path: "/offers?category=Special%20Events",
    title: "home>offers>special",
  },
  { path: "/stay", title: "home>stay" },
  {
    path: "/stay/room/forest-tower/forest-tower-deluxe",
    title: "home>stay>forestdeluxe",
  },
  {
    path: "/stay/room/forest-tower/forest-tower-suite",
    title: "home>stay>forestsuite",
  },
  {
    path: "/stay/room/sun-tower/sun-tower-deluxe",
    title: "home>stay>sundeluxe",
  },
  {
    path: "/stay/room/sun-tower/sun-tower-suite",
    title: "home>stay>sunsuite",
  },
  {
    path: "/stay/room/ocean-tower/ocean-tower-deluxe",
    title: "home>stay>oceandeluxe",
  },
  {
    path: "/stay/room/ocean-tower/ocean-tower-suite",
    title: "home>stay>oceansuite",
  },
  { path: "/stay/villa/villa", title: "home>stay>villa" },
  { path: "/stay/amenities/executive-lounge", title: "home>stay>elounge" },
  { path: "/stay/amenities/INPSIRE-wellness-pool", title: "home>stay>pool" },
  {
    path: "/stay/amenities/INPSIRE-wellness-club",
    title: "home>stay>fitness",
  },
  { path: "/stay/amenities/v-spa", title: "home>stay>vspa" },
  { path: "/stay/amenities/in-room-dining", title: "home>stay>roomdining" },
  { path: "/eat-and-drink", title: "home>eat" },
  {
    path: "/eat-and-drink/signature-dining/chefs-kitchen",
    title: "home>eat>chefs",
  },
  {
    path: "/eat-and-drink/signature-dining/mj-steak-house",
    title: "home>eat>mjsteak",
  },
  { path: "/entertainment", title: "home>entertainment" },
  {
    path: "/entertainment/inspire-arena/featured-performances",
    title: "home>entertainment>concert",
  },
  {
    path: "/entertainment/inspire-arena/inspire-arena",
    title: "home>entertainment>arena",
  },
  {
    path: "/entertainment/attraction-venue/discovery-park",
    title: "home>entertainment>discovery",
  },
  {
    path: "/entertainment/attraction-venue/INPSIRE-wonders",
    title: "home>entertainment>wonders",
  },
  {
    path: "/entertainment/attraction-venue/le-space",
    title: "home>entertainment>lespace",
  },
  { path: "/meet", title: "home>meet" },
  {
    path: "/meet/meeting-success/ballroom",
    title: "home>meet>meetingrooms",
  },
  { path: "/meet/meeting-success/studio", title: "home>meet>studio" },
  {
    path: "/meet/meeting-success/executive-boardroom",
    title: "home>meet>boardroom",
  },
  { path: "/meet/wedding-events/wedding", title: "home>meet" },
  { path: "/meet/wedding-events/events", title: "home>meet" },
  { path: "/meet/meeting-success/business-center", title: "home>meet>bcenter" },
  { path: "/meet/services/inquiry", title: "home>meet" },
  { path: "/meet/sightseeing/leisure", title: "home>meet" },
  { path: "/meet/sightseeing/area-attractions", title: "home>meet" },
  { path: "/casino", title: "home>casino" },
  { path: "/casino/events-promotions", title: "home>casino" },
  { path: "/casino/dining", title: "home>casino" },
  { path: "/casino/momentum-rewards", title: "home>casino" },
  {
    path: "/page/identification-documents-for-foreigners-and-overseas-emigrants",
    title: "x",
  },
  { path: "/momentum", title: "home>momentum" },
  { path: "/momentum/tiers-benefits", title: "home>momentum" },
];
