import React from "react";
import {
  EventGridBox,
  SectionTitle,
  EventButton,
  AWS_BUCKET_URL,
} from "@components/events/winter-wonderland/WinterWonderland";
import styled from "@emotion/styled";
import { ImageWrapperImage } from "@components/item/ItemStyling";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "1.5rem 2rem",

  [mq("desktop")]: {
    padding: "4rem 5rem 5rem 5rem",
  },
}));

const Title = styled.div(() => ({
  marginBottom: "3rem",
  textAlign: "center",
  fontWeight: 300,

  p: {
    fontSize: isJapanese ? "1.2rem" : "1.4rem",
  },

  [mq("desktop")]: {
    p: {
      fontSize: "1.8rem",
    },
  },
}));

const Item = styled.div(({ theme }) => ({
  padding: "1.5rem",
  display: "flex",
  flexDirection: "column",
  background: theme.color.white,

  [mq("desktop")]: {
    padding: "2.5rem",
  },
}));

const Figure = styled.figure(() => ({
  position: "relative",
  aspectRatio: "1 / 1",
}));

const TextAll = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "full-content",
}));

const Description = styled.div(() => ({
  marginTop: "1.2rem",

  header: {
    fontSize: "1.6rem",
    fontWeight: 700,
  },

  [mq("tablet")]: {
    span: {
      display: "inline-block",
    },
  },

  [mq("desktop")]: {
    header: {
      fontSize: "2rem",
    },
  },
}));

const ButtonWrapper = styled.div(() => ({
  textAlign: "center",
  marginTop: "1.6rem",
  whiteSpace: "noWrap",
}));

const SixthSection = ({ intlFormat }) => {
  const arr = [
    {
      id: 1,
      image: "Arena_1.jpg",
      link: "/entertainment/performance/inspire-concert-series-05-kygo",
    },
    {
      id: 2,
      image: "Arena_2.jpg",
      link: "/entertainment/performance/zedd-telos-tour-in-korea",
    },
    {
      id: 3,
      image: "Arena_3.jpg",
      link: "/entertainment/performance/TXT-worldtour-act-promise-ep2-in-incheon",
    },
    {
      id: 4,
      image: "Arena_4.jpg",
      link: "/entertainment/performance/kenshi-yonezu-2025-worldtour-junk",
    },
    {
      id: 5,
      image: "Arena_5.jpg",
      link: "/entertainment/performance/2025-EDC-Korea",
    },
    {
      id: 6,
      image: "Arena_6.jpg",
      link: "/entertainment/performance/inspire-arena-backstage-tour-v2",
    },
  ];

  return (
    <Container>
      <Title>
        <SectionTitle>{intlFormat("winter-section7-title-1")}</SectionTitle>

        <p>{intlFormat("winter-section7-title-2")}</p>
      </Title>

      <EventGridBox column="3">
        {arr.map((item) => {
          return (
            <Item key={item.id}>
              <Figure>
                <ImageWrapperImage
                  src={`${AWS_BUCKET_URL}/${item.image}`}
                  alt={item.title}
                  width="100%"
                />
              </Figure>

              <TextAll>
                <Description>
                  <header>
                    {intlFormat(`winter-section7-content${item.id}-1`)}
                  </header>

                  <span>
                    {intlFormat(`winter-section7-content${item.id}-2`)}
                  </span>
                </Description>

                <ButtonWrapper>
                  <EventButton link={item.link} />
                </ButtonWrapper>
              </TextAll>
            </Item>
          );
        })}
      </EventGridBox>
    </Container>
  );
};

export default SixthSection;
