import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTheme } from "@emotion/react";
import { Backdrop } from "@mui/material";
import he from "he";

import { backdropZIndex } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { BookingModal, MomentumModal } from "@components/modal/modal-list";
import { MovableModal } from "@components/modal";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ErrorManage, LoadingScreen } from "@screens/index";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  BookIcon,
  BookNowButton,
  ChildMenuContainer,
  ChildMenuWrapper,
  Line,
  MainMenu,
  MenuWrapper,
  StyledLink,
  SubMenu,
  SubMenuContainer,
  SubMenuIn,
  SubMenuLeft,
  SubMenuRight,
  SubMenuRightTitle,
  SubMenuWrapper,
  WebHeaderBottom,
  WebHeaderContainer,
  WebHeaderTop,
  WebHeaderUtilBar,
  WebHeaderUtilBarWrapper,
  WebHeaderWrapper,
} from "./HeaderStyling";
import { LanguageModal } from ".";
import { DangerouslyHtml } from "@components/item";
import { BookNowText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  JOIN_MOMENTUM_ENTITY_ID,
  SIGN_IN_ENTITY_ID,
  SIGN_OUT_ENTITY_ID,
} from "@components/header/Header";
import { useRedirectToStrivacity } from "../../hooks/useRedirectToStrivacity";
import { useLogoutUser } from "@utils/api-requests/userRequests";
import { GAClickEvent } from "../../ga/GAConfiguration";
import { removeEmptySpace } from "@utils/modify-data/modifyData";

const WebHeader = ({
  headerList,
  utilbarArr,
  languageModal,
  setLanguageModal,
  headerColored,
  isLoading,
  isError,
  error,
  bookingModal,
  setBookingModal,
}) => {
  const { redirectToLoginPage, redirectToSignUpPage } =
    useRedirectToStrivacity();

  const logout = useLogoutUser({ noRoute: true });

  const [loading, setLoading] = useState(false);

  const intl = useIntl();
  const langModalRef = useRef(null);
  const currentUrl = useLocation().pathname.split("/")[2];

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);

  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const theme = useTheme();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  let agilysysLang;

  // switch (lang) {
  //   case "zh-hans":
  //     agilysysLang = "ZH-CN";
  //     break;
  //   case "zh-hant":
  //     agilysysLang = "ZH-TW";
  //     break;
  //   default:
  //     agilysysLang = lang;
  //     break;
  // }

  switch (lang) {
    case "zh-hans":
      agilysysLang = "ZH-CN";
      break;
    case "zh-hant":
      agilysysLang = "ZH-TW";
      break;
    case "":
      agilysysLang = "en";
      break;
    case null:
      agilysysLang = "en";
      break;
    default:
      agilysysLang = lang;
      break;
  }

  const baseUrl = useMemo(() => {
    if (lang !== null && lang) {
      return `/${lang}`;
    }

    if (lang === null || lang === undefined) {
      window.location.reload(false);
    }
  }, [lang]);

  const headerBlacked = useMemo(() => {
    if (headerColored || menuOpen || languageModal) {
      return true;
    }

    return false;
  }, [headerColored, languageModal, menuOpen]);

  function useOutsideModalClicked(modalRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setLanguageModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [modalRef]);
  }

  useOutsideModalClicked(langModalRef);

  const handleMenuOpen = useCallback((Index) => {
    setMenuOpen(true);

    setMenuIndex(Index);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);

    setMenuIndex(null);
  }, []);

  const handleKeepInTouchModal = useCallback(
    (e, utilmenu) => {
      if (utilmenu.entity_id === SIGN_OUT_ENTITY_ID) {
        // NOTE: when the button is sign out

        logout();
      }

      if (utilmenu.entity_id === SIGN_IN_ENTITY_ID) {
        // NOTE: when the button is sign in or join momentum

        redirectToLoginPage();
      }

      if (utilmenu.entity_id === JOIN_MOMENTUM_ENTITY_ID) {
        redirectToSignUpPage();
      }
    },
    [logout, redirectToLoginPage, redirectToSignUpPage],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return (
    <>
      <MomentumModal
        openModal={signUpModalOpen}
        setOpenModal={setSignUpModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      {loading && <LoadingScreen />}

      <Backdrop
        sx={{
          background: theme.opacity.overlay02,
          zIndex: backdropZIndex,
        }}
        open={menuOpen}
        onClick={handleMenuClose}
      />

      <WebHeaderContainer
        headerColored={headerBlacked}
        onMouseLeave={handleMenuClose}
      >
        <CommonContainer>
          <WebHeaderWrapper>
            <WebHeaderTop>
              <Link
                to={baseUrl}
                onClick={() => {
                  handleMenuClose();
                  setLoading(true);

                  GAClickEvent("click_event", {
                    action: "homebutton",
                    category: "gnb",
                    label: "logo",
                  });
                }}
                aria-label="Link to Home"
              >
                <object
                  loading="lazy"
                  data={
                    headerBlacked
                      ? theme.image.basicLogo.default
                      : theme.image.whiteLogo.default
                  }
                  aria-label="Mohegan Inspire Entertainment Resort"
                  alt="Mohegan Inspire Entertainment Resort"
                  height={50}
                />
              </Link>

              <WebHeaderUtilBarWrapper>
                {utilbarArr.map((utilmenu) => {
                  return (
                    <WebHeaderUtilBar
                      key={utilmenu.entity_id}
                      to={`${utilmenu.url === "/en" ? baseUrl : `${baseUrl}${utilmenu.url}`}`}
                      headercolored={headerBlacked ? "true" : "false"}
                      onClick={(e) => {
                        handleKeepInTouchModal(e, utilmenu);

                        GAClickEvent("click_event", {
                          action: "submenu",
                          category: "gnb",
                          label: removeEmptySpace(utilmenu.enTitle),
                        });
                      }}
                    >
                      <DangerouslyHtml value={utilmenu.title} />
                    </WebHeaderUtilBar>
                  );
                })}

                <LanguageModal
                  langModalRef={langModalRef}
                  headerBlacked={headerBlacked}
                  languageModal={languageModal}
                  setLanguageModal={setLanguageModal}
                />
              </WebHeaderUtilBarWrapper>
            </WebHeaderTop>

            <MovableModal
              modalName={intl.formatMessage({
                id: "lang-room-reservation",
              })}
              open={bookingModal}
              setOpen={setBookingModal}
            >
              <BookingModal />
            </MovableModal>
          </WebHeaderWrapper>

          <ErrorManage isLoading={isLoading} isError={isError} error={error}>
            {headerList && (
              <MenuWrapper>
                <WebHeaderBottom>
                  {headerList?.map((item, index) => {
                    return (
                      <div key={item.entity_id}>
                        <MainMenu
                          to={`${baseUrl}${item.url}`}
                          type="button"
                          onClick={() => {
                            handleMenuClose();

                            GAClickEvent("click_event", {
                              action: removeEmptySpace(item.enTitle),
                              category: "gnb",
                              label: removeEmptySpace(item.enTitle),
                            });
                          }}
                          onMouseEnter={() => handleMenuOpen(index)}
                          active={
                            item.url === `/${currentUrl}` ? "true" : "false"
                          }
                        >
                          <DangerouslyHtml value={item.title} />
                        </MainMenu>
                      </div>
                    );
                  })}

                  <Link
                    to={`https://book.rguest.sg/onecart/wbe/offers/20044/InspireEntertainmentResort?langCode=${agilysysLang?.toUpperCase()}`}
                    target="_blank"
                  >
                    <BookNowButton
                      headerColored={headerBlacked}
                      onClick={() => {
                        GAClickEvent("click_event", {
                          action: "booknow",
                          category: "gnb",
                          label: "booknow",
                        });
                      }}
                    >
                      <BookIcon headerColored={headerBlacked} />

                      <BookNowText />
                    </BookNowButton>
                  </Link>
                </WebHeaderBottom>

                <SubMenuContainer
                  active={
                    headerList[menuIndex]?.children.length > 0 &&
                    menuIndex !== null
                  }
                >
                  {headerList[menuIndex]?.children && menuIndex !== null}
                  <SubMenuIn>
                    <SubMenuWrapper>
                      <SubMenuLeft>
                        {headerList[menuIndex]?.children.map(
                          (subMenu, idx, arr) => {
                            const subChildMenu = subMenu.children;

                            return (
                              <ChildMenuContainer key={subMenu.entity_id}>
                                <SubMenu>
                                  <DangerouslyHtml value={subMenu.title} />
                                </SubMenu>

                                <ChildMenuWrapper>
                                  {subChildMenu &&
                                    subChildMenu.map((childMenu) => {
                                      return (
                                        <StyledLink
                                          key={childMenu.entity_id}
                                          to={`${baseUrl}${childMenu.url}${
                                            subMenu.category_id !== null
                                              ? `/${subMenu.category_id.replaceAll(
                                                  " ",
                                                  "-",
                                                )}`
                                              : ""
                                          }${
                                            childMenu.content_link !== ""
                                              ? `${childMenu.content_link}`
                                              : ""
                                          }${
                                            childMenu?.category_id !== null
                                              ? `?category=${childMenu.category_id}`
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleMenuClose();

                                            GAClickEvent("click_event", {
                                              action: removeEmptySpace(
                                                headerList[menuIndex].enTitle,
                                              ),
                                              category: "gnb",
                                              label: removeEmptySpace(
                                                childMenu.enTitle,
                                              ),
                                            });
                                          }}
                                          state={{
                                            tab: subMenu.ref_category_id,
                                          }}
                                        >
                                          <DangerouslyHtml
                                            value={childMenu.title}
                                          />

                                          <Line />
                                        </StyledLink>
                                      );
                                    })}
                                </ChildMenuWrapper>
                              </ChildMenuContainer>
                            );
                          },
                        )}
                      </SubMenuLeft>

                      <SubMenuRight>
                        {headerList[menuIndex]?.offer.thumbnail.length > 0 && (
                          <Link
                            to={`${baseUrl}/offers${headerList[menuIndex]?.offer.url_alias}`}
                          >
                            <ImageWrapper ratio="3 / 2" radius="2.5rem">
                              <Image
                                src={
                                  headerList[menuIndex]?.offer.thumbnail[0]?.src
                                }
                                alt={
                                  headerList[menuIndex]?.offer.thumbnail[0]?.alt
                                }
                              />

                              <GradientOverlay />

                              <SubMenuRightTitle>
                                {he.decode(headerList[menuIndex]?.offer.title)}
                              </SubMenuRightTitle>
                            </ImageWrapper>
                          </Link>
                        )}
                      </SubMenuRight>
                    </SubMenuWrapper>
                  </SubMenuIn>
                </SubMenuContainer>
              </MenuWrapper>
            )}
          </ErrorManage>
        </CommonContainer>
      </WebHeaderContainer>
    </>
  );
};

export default memo(WebHeader);
