import React, { memo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { NormalOverlay } from "@components/item/Overlays";
import { DangerouslyHtml } from "@components/item";
import { MomentumModal } from "@components/modal/modal-list";
import { PrimaryButton } from "@components/buttons";
import { SignUpNowText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { Image, ImageWrapper } from "../item/ImageRelated";
import {
  KeepInTouchCopywrite,
  KeepInTouchInformation,
  KeepInTouchInformationWrapper,
  KeepInTouchWrapper,
} from "./LayoutStyling";
import { useRedirectToStrivacity } from "../../hooks/useRedirectToStrivacity";
import { GAClickEvent } from "../../ga/GAConfiguration";

const KeepInTouch = ({ data }) => {
  const { redirectToSignUpPage } = useRedirectToStrivacity();

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  return (
    <CommonContainer>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <KeepInTouchWrapper>
        <ImageWrapper
          custom={{ flex: 1 }}
          ratio={isDesktop ? "3 / 2" : "16 / 9"}
          radius="2.5rem"
        >
          <NormalOverlay opacity="0.4" />

          <Image
            src={data?.field_thumbnail[0]?.src}
            alt={data?.field_thumbnail[0]?.alt}
          />

          <KeepInTouchCopywrite>
            <CommonTitleOne>
              <DangerouslyHtml value={data?.field_sign_up_copywrite} />
            </CommonTitleOne>

            <CommonDescOne>
              <DangerouslyHtml value={data?.field_short_description} />
            </CommonDescOne>
          </KeepInTouchCopywrite>
        </ImageWrapper>

        <KeepInTouchInformation>
          <KeepInTouchInformationWrapper>
            <CommonTitleOne>
              <DangerouslyHtml value={data?.field_sign_up_title} />
            </CommonTitleOne>

            <CommonDescOne>
              <DangerouslyHtml value={data?.field_sign_up_instruction} />
            </CommonDescOne>
          </KeepInTouchInformationWrapper>

          {sessionStorage.getItem("signupAction") !== "false" && (
            <PrimaryButton
              buttonEvent={() => {
                redirectToSignUpPage();

                GAClickEvent("click_event", {
                  action: "momentum",
                  category: "footer",
                  label: "signupnow",
                });
              }}
            >
              <SignUpNowText />
            </PrimaryButton>
          )}
        </KeepInTouchInformation>
      </KeepInTouchWrapper>
    </CommonContainer>
  );
};

export default memo(KeepInTouch);
