import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/ErrorManage";
import { useGetMyProfile } from "@utils/api-requests/MomentumRequests";

import { DashboardAccount, WelcomeMessage } from ".";
import {
  useGetMomentumPointsEngage,
  useGetStrivacityToken,
  usePostStrivacityRefreshToken,
} from "@utils/api-requests/StrivacityReqeusts";

const MyProfile = () => {
  const urlSearch = window.location.search;
  const params = new URLSearchParams(urlSearch);
  const code = params.get("code");

  const [userData, setUserData] = useState();
  const [lifeStyleId, setLifeStyleId] = useState();

  const { data: profile } = useGetMomentumPointsEngage(
    userData?.lifestyle_id?.replaceAll(" ", ""),
  );

  // NOTE: fetch tokens for the first time
  const { data: tokens } = useGetStrivacityToken(code);

  // NOTE: fetch refresh tokens
  const { data: refreshToken } = usePostStrivacityRefreshToken();

  useEffect(() => {
    if (tokens) {
      setLifeStyleId(jwtDecode(tokens?.id_token)?.user_id);
      setUserData(jwtDecode(tokens?.id_token));
    } else if (refreshToken) {
      setLifeStyleId(jwtDecode(refreshToken?.id_token)?.user_id);
      setUserData(jwtDecode(refreshToken?.id_token));
    }
  }, [tokens, refreshToken, lifeStyleId]);

  const [currentTab, setCurrentTab] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [changeMarketingModalOpen, setChangeMarketingModalOpen] =
    useState(false);

  const { data: myProfile, isLoading, isError, error } = useGetMyProfile();

  useEffect(() => {
    const currentTier =
      profile?.ProfileData?.TierInfo?.CurrentTierInfo[0]?.TierName;

    let nowTierIndex = 0;

    const tierIndexMap = {
      "INSPIRE LIFE": 0,
      "INSPIRE ELITE": 1,
      "INSPIRE PRESTIGE": 2,
    };

    nowTierIndex = tierIndexMap[currentTier] || nowTierIndex;

    setCurrentIndex(nowTierIndex);
  }, [profile, userData]);

  const sectionArray = [
    {
      id: 1,

      contents: (
        <>
          <WelcomeMessage
            data={myProfile}
            userData={userData}
            profile={profile}
            currentIndex={currentIndex}
          />

          <DashboardAccount
            data={myProfile}
            currentIndex={currentIndex}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            userData={userData}
            changeMarketingModalOpen={changeMarketingModalOpen}
            setChangeMarketingModalOpen={setChangeMarketingModalOpen}
          />
        </>
      ),
    },
  ];

  const data = {
    field_enable_section_1: "On",
  };

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <SubpageHero pageData={myProfile} small />

      <CommonContainer>
        <PageContainer data={data} sectionArray={sectionArray} />
      </CommonContainer>
    </ErrorManage>
  );
};

export default MyProfile;
