import { useCallback } from "react";

import {
  LOCAL_STORAGE_LANGUAGE,
  STRIVACITY_BRAND_DOMAIN,
  STRIVACITY_CLIENT_ID,
  STRIVACITY_JOIN_REDIRECT,
  STRIVACITY_NONCE,
  STRIVACITY_REDIRECT,
  STRIVACITY_RESPONSE_TYPE,
  STRIVACITY_SCOPE,
  STRIVACITY_STATE,
} from "@utils/api-requests/static-data";
import { strivacityLanguage } from "@utils/lanugauge-settings/LanguageOptions";

const sign = require("jwt-encode");

export const useRedirectToStrivacity = () => {
  const siteLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const loginHint = {
    language: {
      force: strivacityLanguage(siteLang),
    },
  };

  const secret = "secret";

  const encodedLoginHint = sign(loginHint, secret);

  const STRIVACITY_SIGNIN_REDIRECT_URI = `${STRIVACITY_BRAND_DOMAIN}/oauth2/auth?client_id=${STRIVACITY_CLIENT_ID}&redirect_uri=${STRIVACITY_REDIRECT}&response_type=${STRIVACITY_RESPONSE_TYPE}&scope=${STRIVACITY_SCOPE}&nonce=${STRIVACITY_NONCE}&state=${STRIVACITY_STATE}&login_hint=${encodedLoginHint}`;
  const STRIVACITY_JOIN_REDIRECT_URI = `${STRIVACITY_BRAND_DOMAIN}/oauth2/auth?client_id=${STRIVACITY_CLIENT_ID}&redirect_uri=${STRIVACITY_JOIN_REDIRECT}&response_type=${STRIVACITY_RESPONSE_TYPE}&scope=${STRIVACITY_SCOPE}&nonce=${STRIVACITY_NONCE}&state=${STRIVACITY_STATE}&prompt=create&login_hint=${encodedLoginHint}`;

  const redirectToLoginPage = useCallback(() => {
    window.location.href = STRIVACITY_SIGNIN_REDIRECT_URI;
  }, [STRIVACITY_SIGNIN_REDIRECT_URI]);

  const redirectToSignUpPage = useCallback(() => {
    window.location.href = STRIVACITY_JOIN_REDIRECT_URI;
  }, [STRIVACITY_JOIN_REDIRECT_URI]);

  return {
    redirectToLoginPage,
    redirectToSignUpPage,
  };
};
