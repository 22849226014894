import { useCallback } from "react";
import axios from "axios";
import { useQueries, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  EatDrinkReservationModalUrl,
  UnauthorizedEmailModalUrl,
  arenaInquiryModalUrl,
  detailPageInfoUrl,
  footerAddressUrl,
  footerContactUrl,
  footerMenuUrl,
  footerSnsUrl,
  headerListUrl,
  headerUtilBarUrl,
  homeBannerSliderUrl,
  homeTopBannerUrl,
  ipapiUrl,
  keepInTouchModalUrl,
  offerRecommendationUrl,
  pageInfoUrl,
  pdfFileUrl,
  redirectUrlsUrl,
  roomReservationModalUrl,
  signInUrl,
} from "./apiUrls";

import * as ApiUrls from "./apiUrls";

import { LOCAL_STORAGE_LANGUAGE } from "./static-data";
import { combineEnglishNameMenuTree } from "@utils/modify-data/modifyData";

// for getting country based on user's ip
export function useIpInformation() {
  const navigate = useNavigate();

  const key = "MAx1pwse1MzxYvD";

  const getIpInformation = useCallback(async () => {
    try {
      const { status, data } = await axios.get(`${ipapiUrl}?key=${key}`);

      if (status === 200) {
        return data;
      }
    } catch (error) {
      navigate("/en");
    }
  }, [navigate]);

  const { data: ipInformation } = useQuery(
    ["ipInformation"],
    getIpInformation,
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: 60 * 1000 * 180,
      refetchIntervalInBackground: true,
      suspense: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );

  return { ipInformation };
}

// OfferRecommendation.jsx
export function useGetOfferRecommendation(idList) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${offerRecommendationUrl}?language=${language}&id=${idList}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, idList]);
  return useQuery(
    ["offerRecommendation", language, idList],
    getOfferRecommendation,
    {
      enabled: !!language && !!idList,
    },
  );
}

// SignIn.jsx
export function useGetSignIn() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSignIn = useCallback(async () => {
    const { status, data } = await axios.get(
      `${signInUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["signIn", language], getSignIn, {
    enabled: !!language,
  });
}

// Home.jsx
export function useGetHomeInformation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeInformation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeBannerSliderUrl}?language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["homeInformation", language], getHomeInformation, {
    enabled: !!language,
  });
}

// RoomReservationModal.jsx
export function useGetRoomReservationModal() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRoomReservationModal = useCallback(async () => {
    const { status, data } = await axios.get(
      `${roomReservationModalUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["roomReservationModal", language], getRoomReservationModal, {
    enabled: !!language,
  });
}

// MomentumModal.jsx
export function useGetKeepInTouchModal() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getKeepInTouchModal = useCallback(async () => {
    const { status, data } = await axios.get(
      `${keepInTouchModalUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["keepInTouchModal", language], getKeepInTouchModal, {
    enabled: !!language,
  });
}

// MarketingModal.jsx
export function useGetContent(query) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const url = ApiUrls[query + "Url"];

  const getContent = useCallback(async () => {
    const { status, data } = await axios.get(`${url}?language=${language}`);

    if (status === 200) {
      return data[0];
    }
  }, [language, url]);

  return useQuery([query, language], getContent, {
    enabled: !!language,
  });
}

// UnauthorizedEmailModal.jsx
export function useGetUnauthorizedEmailModal() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getUnauthorizedEmailModal = useCallback(async () => {
    const { status, data } = await axios.get(
      `${UnauthorizedEmailModalUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["unauthorizedEmailModal", language],
    getUnauthorizedEmailModal,
    {
      enabled: !!language,
    },
  );
}

// ArenaInquiryModal.jsx
export function useGetArenaInquiryModal() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getArenaInquiryModal = useCallback(async () => {
    const { status, data } = await axios.get(
      `${arenaInquiryModalUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["arenaInquiryModal", language], getArenaInquiryModal, {
    enabled: !!language,
  });
}

// EatDrinkReservationModal.jsx
export function useGetEatDrinkReservationModal() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEatDrinkReservationModal = useCallback(async () => {
    const { status, data } = await axios.get(
      `${EatDrinkReservationModalUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["eatDrinkReservationModal", language],
    getEatDrinkReservationModal,
    {
      enabled: !!language,
    },
  );
}

// MainAdBanner.jsx
export function useGetHomeTopBanner() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeTopBanner = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeTopBannerUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["homeTopBanner", language], getHomeTopBanner, {
    enabled: !!language,
  });
}

// For getting information for all pages
export function useGetPageInformation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getPageInformation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${pageInfoUrl}?language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["pageInformation", language], getPageInformation, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// For getting information for detail pages
export function useGetDetailPageInformation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getDetailPageInformation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${detailPageInfoUrl}?language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["detailPageInformation", language],
    getDetailPageInformation,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    },
  );
}

// Header.jsx
export function useGetHeaderListQuery() {
  const { ipInformation } = useIpInformation();
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHeaderList = useCallback(async () => {
    const { status: status1, data: data1 } = await axios.get(
      `${headerListUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    const { status: status2, data: data2 } = await axios.get(
      `${headerListUrl}&language=en&code=${ipInformation?.countryCode}`,
    );

    if (status1 === 200 && status2 === 200) {
      return combineEnglishNameMenuTree(data1.data, data2.data);
    }

    return [];
  }, [language, ipInformation?.countryCode]);

  return useQuery(
    // ["headerList", language, ipInformation, localStorage],
    ["headerList"],
    getHeaderList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!ipInformation?.countryCode,
    },
  );
}

export function useGetHeaderUtilBarQuery() {
  const { ipInformation } = useIpInformation();

  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHeaderUtilBar = useCallback(async () => {
    const { status: status1, data: data1 } = await axios.get(
      `${headerUtilBarUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    const { status: status2, data: data2 } = await axios.get(
      `${headerUtilBarUrl}&language=en&code=${ipInformation?.countryCode}`,
    );

    if (status1 === 200 && status2 === 200) {
      return combineEnglishNameMenuTree(data1.data, data2.data);
    }
  }, [language, ipInformation]);

  return useQuery(
    // ["headerUtilBar", language, ipInformation, localStorage],
    ["headerUtilBar"],
    getHeaderUtilBar,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!ipInformation?.countryCode,
    },
  );
}

// Footer.jsx
export function useGetFooterQuery() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const { ipInformation } = useIpInformation();

  const getFooterAddress = useCallback(async () => {
    const { status, data } = await axios.get(
      `${footerAddressUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  const getFooterMenu = useCallback(async () => {
    const { status: status1, data: data1 } = await axios.get(
      `${footerMenuUrl}&language=${language}&code=KR`,
    );

    const { status: status2, data: data2 } = await axios.get(
      `${footerMenuUrl}&language=en&code=KR`,
    );

    if (status1 === 200 && status2 === 200) {
      return combineEnglishNameMenuTree(data1.data, data2.data);
    }
  }, [language]);

  const getFooterContact = useCallback(async () => {
    const { status, data } = await axios.get(
      `${footerContactUrl}&language=${language}&code=${ipInformation?.countryCode}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language, ipInformation]);

  const getFooterSns = useCallback(async () => {
    const { status, data } = await axios.get(
      `${footerSnsUrl}&language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  const options = {
    enabled: !!language && !!ipInformation?.countryCode,
  };

  const results = useQueries([
    {
      queryKey: ["footerAddress", language],
      queryFn: async () => getFooterAddress(),
      ...options,
    },
    {
      queryKey: ["footerMenu", language, ipInformation],
      queryFn: async () => getFooterMenu(),
      ...options,
    },
    {
      queryKey: ["footerContact", language],
      queryFn: async () => getFooterContact(),
      ...options,
    },
    {
      queryKey: ["footerSns", language],
      queryFn: async () => getFooterSns(),
      ...options,
    },
  ]);

  const footerAddress =
    results[0].data !== undefined && results[0].data !== null
      ? results[0].data[0]
      : [];
  const footerMenu =
    results[1].data !== undefined && results[1].data !== null
      ? results[1].data
      : [];
  const footerContact =
    results[2].data !== undefined && results[2].data !== null
      ? results[2].data[0]
      : [];
  const footerSns =
    results[3].data !== undefined && results[3].data !== null
      ? results[3].data[0]
      : [];

  return {
    footerAddress,
    footerMenu,
    footerContact,
    footerSns,
  };
}

// pdf file upload
export function useGetPdfFileList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getPdfFileList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${pdfFileUrl}?language=${language}`,
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["pdfFileList", language], getPdfFileList, {
    notifyOnChangeProps: "tracked",
  });
}

// RedirectUrls.jsx
export function useGetRedirectUrls() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getRedirectUrls = useCallback(async () => {
    const { status, data } = await axios.get(
      `${redirectUrlsUrl}?language=${language}`,
    );

    if (status === 200) {
      return data[0]?.field_redirection_links;
    }
  }, [language]);

  return useQuery(["redirectUrls", language], getRedirectUrls);
}
