import styled from "@emotion/styled";
import { CommonTitleOne, CommonTitleThree } from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: MomentumLifestyle.jsx

// STYLE: MomentumNewMember.jsx
export const MomentumNewMemberContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 7.2rem",
  alignItems: "center",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MomentumNewMemberInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  color: theme.color.primary_grey,
}));

export const MomentumNewMemberTitle = styled(CommonTitleOne)(({ theme }) => ({
  color: theme.color.base_black,
}));

// STYLE: TiersBenefits.jsx
export const TiersBenefitsContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 0",
}));

// STYLE: MembershipTierStandard.jsx
export const MembershipTierStandardContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "2rem 0",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MembershipTierStandardTitle = styled(CommonTitleThree)(
  ({ theme }) => ({
    fontWeight: theme.fontWeight.Black,
  }),
);

export const MembershipTierStandardList = styled.ul(() => ({
  marginTop: "3.2rem",
  paddingLeft: "3rem",

  "&>li": {
    listStyle: "initial",
    marginBottom: "2rem",
  },
}));
