import React from "react";
import styled from "@emotion/styled";
import {
  AWS_BUCKET_URL,
  EventGridBox,
  SectionTitle,
  SectionWrapper,
  EventButton,
} from "@components/events/winter-wonderland/WinterWonderland";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Title = styled.div(() => ({
  marginBottom: "2.5rem",
  textAlign: "center",
  fontWeight: 300,
  fontSize: isJapanese ? "1.2rem" : "1.4rem",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

const EventItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1.5rem",
  background: theme.color.white,

  [mq("desktop")]: {
    padding: "2.5rem",
  },
}));

const TextAll = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "full-content",
}));

const Description = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.4rem 0",
  fontWeight: 700,
  paddingTop: "1.6rem",

  header: {
    fontSize: "1.6rem",
  },

  p: {
    fontSize: "1.2rem",
    fontWeight: 400,
  },

  span: {
    color: "#460369",
  },

  [mq("tablet")]: {},

  [mq("desktop")]: {
    paddingTop: "2.4rem",

    header: {
      fontSize: "2rem",
    },

    p: {
      fontSize: "1.6rem",
      fontWeight: 400,
    },
  },
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1.4rem",
  width: "100%",
  marginTop: "1.6rem",
  color: "#68507C",
}));

const SecondSection = ({ intlFormat }) => {
  const arr = [
    // TODO: uncomment when splashbay is ready
    {
      id: 1,
      image: "Room_Offer_1.png",
      bookLink: "14ATEABR",
      viewLink:
        "/offers/detail/winter-strawberry-afternoontea-package-with-aperol-spritz",
    },
    {
      id: 2,
      image: "Room_Offer_2.png",
      title: "Splash Bay Room Package",
      date: "Stay Period | Jan 15, 2025 - Apr 30, 2025",
      price: "256,000 KRW ~",
      bookLink: "14EFLP",
      viewLink: "/offers/detail/executive-lounge-package",
    },
    {
      id: 3,
      image: "Room_Offer_3.png",
      title: "Chef’s Kitchen Breakfast Package (Session 2)",
      date: "Stay Period | - Apr 30, 2025",
      price: "316,000 KRW ~ ",
      bookLink: "14SPECIAL",
      viewLink: "/offers/detail/special-celebrations-package-campaign",
    },
    {
      id: 4,
      image: "Room_Offer_4.png",
      title: "2025 Lunar New Year : In-Room Dining Special",
      date: "Stay Period | Jan 13, 2024 - Jan 31, 2025",
      price: "303,000 KRW ~",
      bookLink: "12INCK2",
      viewLink: "/offers/detail/chefs-kitchen-breakfast-package-session2",
    },
  ];

  return (
    <SectionWrapper>
      <Title>
        <SectionTitle>{intlFormat("winter-section3-title-1")}</SectionTitle>

        <p>{intlFormat("winter-section3-title-2")}</p>
      </Title>

      <EventGridBox>
        {arr.map((item) => {
          return (
            <EventItem key={item.id}>
              <img
                src={`${AWS_BUCKET_URL}/${item.image}`}
                alt={item.title}
                width="100%"
              />

              <TextAll>
                <Description>
                  <header>
                    {intlFormat(`winter-section3-content${item.id}-1`)}
                  </header>

                  <p>{intlFormat(`winter-section3-content${item.id}-2`)}</p>

                  <span>
                    {intlFormat(`winter-section3-content${item.id}-3`)}
                  </span>
                </Description>

                <ButtonWrapper>
                  <EventButton
                    primary
                    rguestLink={item.bookLink}
                    buttonName={intlFormat("lang-book-now")}
                    customLink={item.customLink ? item.customLink : undefined}
                  />

                  <EventButton link={item.viewLink} />
                </ButtonWrapper>
              </TextAll>
            </EventItem>
          );
        })}
      </EventGridBox>
    </SectionWrapper>
  );
};

export default SecondSection;
