import React from "react";
import {
  AWS_BUCKET_URL,
  EventGridBox,
  SectionTitle,
  EventButton,
} from "@components/events/winter-wonderland/WinterWonderland";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

const Container = styled.div(() => ({
  background: "#F3F3F3",
  width: "100%",
  padding: "2rem",

  [mq("desktop")]: {
    padding: "4rem 5rem 5rem 5rem",
  },
}));

const Title = styled.div(() => ({
  textAlign: "center",
  fontWeight: 300,
  marginBottom: "2.5rem",

  p: {
    fontSize: isJapanese ? "1.2rem" : "1.4rem",
  },

  [mq("desktop")]: {
    p: {
      fontSize: "1.8rem",
    },
  },
}));

const Item = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1.5rem",
  background: theme.color.white,

  [mq("desktop")]: {
    padding: "2.5rem",
  },
}));

const TextAll = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "full-content",
}));

const Description = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem 0",
  margin: "1.6rem 0 2rem",
  fontWeight: 700,

  header: {
    fontSize: "1.6rem",
  },

  span: {
    fontWeight: 400,
  },

  p: {
    color: "#460369",
  },

  [mq("desktop")]: {
    header: {
      fontSize: "2rem",
    },

    p: {
      minHeight: "2rem",
    },
  },
}));

const ButtonWrapper = styled.div(() => ({
  textAlign: "center",
}));

const FourthSection = ({ intlFormat }) => {
  const arr = [
    {
      id: 1,
      image: "F&B_1.jpg",
      link: "/offers/detail/students-and-graduates-dining-special",
    },
    {
      id: 2,
      image: "F&B_2.jpg",
      link: "/offers/detail/dessert-buffet-strawberry-delights",
    },
    {
      id: 3,
      image: "F&B_3.jpg",
      link: "/offers/detail/INSPIREs-valentine-delights",
    },
  ];

  return (
    <Container>
      <Title>
        <SectionTitle>{intlFormat("winter-section5-title-1")}</SectionTitle>

        <p>{intlFormat("winter-section5-title-2")}</p>
      </Title>

      <EventGridBox column="3">
        {arr.map((item) => {
          return (
            <Item key={item.id}>
              <img
                src={`${AWS_BUCKET_URL}/${item.image}`}
                alt={item.title}
                width="100%"
              />

              <TextAll>
                <Description>
                  <header>
                    {intlFormat(`winter-section5-content${item.id}-1`)}
                  </header>

                  <span>
                    {intlFormat(`winter-section5-content${item.id}-2`)}
                  </span>

                  <p>{intlFormat(`winter-section5-content${item.id}-3`)}</p>
                </Description>

                <ButtonWrapper>
                  <EventButton link={item.link} />
                </ButtonWrapper>
              </TextAll>
            </Item>
          );
        })}
      </EventGridBox>
    </Container>
  );
};

export default FourthSection;
