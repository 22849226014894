import React from "react";

import { IsDesktop } from "@utils/media-query/mediaQuery";

import {
  MyProfileAccountContainer,
  MyProfileAccountImage,
  MyProfileAccountInformation,
  MyProfileAccountInformationWrapper,
} from "./MyProfileStyling";

import { AccountBasicInfo } from "@pages/momentum/my-profile/index";

const MyProfileAccount = ({ data, userData }) => {
  return (
    <MyProfileAccountContainer>
      <MyProfileAccountInformation>
        <MyProfileAccountInformationWrapper>
          <AccountBasicInfo userData={userData} />
        </MyProfileAccountInformationWrapper>
      </MyProfileAccountInformation>

      <IsDesktop>
        <MyProfileAccountImage
          src={`${process.env.REACT_APP_SERVER_URL}${data?.field_image[0].src}`}
          alt={data?.field_image[0].alt}
        />
      </IsDesktop>
    </MyProfileAccountContainer>
  );
};

export default MyProfileAccount;
