import React from "react";

import { FrameTwo } from "@components/frame";
import { SecondaryButton } from "@components/buttons";
import {
  CasinoText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetCasinoTiers } from "@utils/api-requests/CasinoRequests";
import { GAClickEvent } from "../../ga/GAConfiguration";

const CasinoMemebershipTiers = () => {
  const { data: casinoTiers } = useGetCasinoTiers();

  return (
    <FrameTwo
      subTitle={<CasinoText />}
      title={casinoTiers?.field_thumbnail_title}
      desc={casinoTiers?.field_short_description}
      image={casinoTiers?.field_thumbnail?.[0]}
      direction="right"
      tertiary
      button={
        <div
          onClick={() => {
            GAClickEvent("click_event", {
              action: "momentum",
              category: "casino",
              label: "viewdetails",
            });
          }}
        >
          <SecondaryButton
            buttonColor="white"
            linkTo="/casino/momentum-rewards"
          >
            <ViewDetailsText />
          </SecondaryButton>
        </div>
      }
    />
  );
};

export default CasinoMemebershipTiers;
