import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListFiveContainer,
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
  BoxListFiveTexts,
  BoxListFiveTitle,
} from "@components/box-list/BoxListStyling";
import {
  BoxListFiveDetailButton,
  BoxListFiveOverlays,
} from "@components/box-list/BoxListFive";
import { ErrorManage } from "@screens/index";
import { useGetAmenityList } from "@utils/api-requests/StayRequests";
import { mq } from "@utils/media-query/mediaQuery";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import {
  changeRatioDependingDataLength,
  removeEmptySpace,
} from "@utils/modify-data/modifyData";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";
import { DangerouslyHtml } from "@components/item";
import { GAClickEvent } from "../../ga/GAConfiguration";

const Amenities = () => {
  const amenitiesRef = useRef();

  const search = useLocation().search;

  const [onHover, setOnHover] = useState(-1);

  const { data: amenityList, isLoading, isError, error } = useGetAmenityList();

  const onMouseEnter = (i) => {
    setOnHover(i);
  };

  const onMouseLeave = () => {
    setOnHover(-1);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (search.includes("amenities")) {
        scrollToTargetAdjusted(amenitiesRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  const length = amenityList?.length % 6;

  return (
    <div ref={amenitiesRef}>
      <CommonContainer>
        <BoxListFiveContainer length={length}>
          <ErrorManage
            isLoading={isLoading}
            isError={isError}
            error={error}
            noLoading
          >
            {amenityList?.map((item, idx) => {
              return (
                <BoxListFiveGridArea
                  key={item.uuid}
                  onMouseEnter={() => onMouseEnter(idx)}
                  onMouseLeave={onMouseLeave}
                >
                  <ImageWrapper
                    custom={{
                      [mq("tablet")]: {
                        aspectRatio: "1 / 1",
                      },

                      [mq("desktop")]: {
                        aspectRatio: changeRatioDependingDataLength(
                          length,
                          idx,
                        ),
                      },
                    }}
                    radius="1.5rem"
                    idx={idx}
                  >
                    <Image
                      src={item.field_thumbnail[0]?.src}
                      alt={item.field_thumbnail[0]?.alt}
                    />

                    <BoxListFiveInformation
                      align={idx === 0 ? "row" : "column"}
                    >
                      <BoxListFiveTexts>
                        <BoxListFiveTitle>
                          <DangerouslyHtml value={item.field_amenity_name} />
                        </BoxListFiveTitle>

                        <BoxListFiveDesc className="ellipsis-3">
                          <DangerouslyHtml
                            value={item.field_amenity_short_description}
                          />
                        </BoxListFiveDesc>
                      </BoxListFiveTexts>

                      <div
                        onClick={() => {
                          GAClickEvent("click_event", {
                            action: "amenities",
                            category: "stay",
                            label: removeEmptySpace(item.enTitle),
                          });
                        }}
                      >
                        <BoxListFiveDetailButton
                          onHover={onHover}
                          idx={idx}
                          item={item}
                          url="/stay/amenities"
                        />
                      </div>
                    </BoxListFiveInformation>

                    <BoxListFiveOverlays onHover={onHover} idx={idx} />
                  </ImageWrapper>
                </BoxListFiveGridArea>
              );
            })}
          </ErrorManage>
        </BoxListFiveContainer>
      </CommonContainer>
    </div>
  );
};

export default Amenities;
