import React from "react";

import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { PrimaryButton } from "@components/buttons";
import { BoxListFour } from "@components/box-list";
import { useGetCasinoEventsDetail } from "@utils/api-requests/CasinoRequests";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { GAClickEvent } from "../../ga/GAConfiguration";

const CasinoEvents = () => {
  const { data: casinoEventsDetail } = useGetCasinoEventsDetail(
    undefined,
    true,
  );

  return (
    <CommonContentButtonWrapper>
      <BoxListFour
        data={casinoEventsDetail?.slice(0, 3)}
        dateKey="field_bookable_end_date"
        imageKey="field_thumbnail"
        descKey="field_short_description"
        nameKey="field_event_name"
        linkTo="/casino/events-promotions"
        gaEvents
      />

      <PrimaryButton
        linkTo="/casino/events-promotions"
        buttonEvent={() => {
          GAClickEvent("click_event", {
            action: "promotion",
            category: "casino",
            label: "viewall",
          });
        }}
      >
        <ViewAllText />
      </PrimaryButton>
    </CommonContentButtonWrapper>
  );
};

export default CasinoEvents;
