import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { GA_TITLE_PATHS } from "./page-view-title";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

export function GAClickEvent(eventName, eventParams) {
  ReactGA.event(eventName, eventParams);
}
export const RoutePageViewTracker = () => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const location = useLocation();

  const [initialized, setInitialized] = useState(false);

  // 구글 애널리틱스 운영서버만 적용
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_A_4_ID) {
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GOOGLE_A_4_ID,
        },
      ]);

      setInitialized(true);
    }
  }, []);

  const getGATitle = useCallback(
    (pathname) => {
      const matched = GA_TITLE_PATHS.find((item) => {
        return `/${lang}${item.path}` === pathname;
      });
      return matched ? matched.title : undefined;
    },
    [lang],
  );

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    const title = getGATitle(`${location.pathname}${location.search}`);

    if (initialized && title) {
      // ReactGA.set({ page: location.pathname });

      try {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
          title,
        });
      } catch (e) {
        console.log("Google Analytics의 trackPageView 실행 오류", {
          Error: e,
        });
      }
    }
  }, [getGATitle, initialized, location]);
};
