import React, { useCallback, useMemo, useState } from "react";
import { CommonDescFive } from "@assets/styles/CommonStyles";
import {
  MyProfileAccountEditPassword,
  MyProfileAccountValue,
} from "@pages/momentum/my-profile/MyProfileStyling";
import { EditText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { changeEmptyValue } from "@utils/modify-data/modifyData";
import {
  NoText,
  YesText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { useIntl } from "react-intl";
import {
  ChangeMarketingModal,
  ChangePasswordModal,
} from "@components/modal/modal-list";
import ChangePersonalModal from "@components/modal/modal-list/ChangePersonalModal";
import {
  MARKETING_CONSENT_ID,
  PERSONAL_CONSENT_ID,
} from "@utils/api-requests/static-data";
import { useGetStrivacityConsent } from "@utils/api-requests/StrivacityReqeusts";

const AccountBasicInfo = ({ userData }) => {
  const { data: strivacityConsentList } = useGetStrivacityConsent();

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const marketingConsent = useMemo(() => {
    if (strivacityConsentList) {
      return strivacityConsentList.find(
        (el) => el.consentId === MARKETING_CONSENT_ID,
      );
    }
  }, [strivacityConsentList]);

  const personalConsent = useMemo(() => {
    if (strivacityConsentList) {
      return strivacityConsentList.find(
        (el) => el.consentId === PERSONAL_CONSENT_ID,
      );
    }
  }, [strivacityConsentList]);

  const [changeConsentMarketingModalOpen, setChangeConsentMarketingModalOpen] =
    useState(false);
  const [changeConsentPersonalModalOpen, setChangeConsentPersonalModalOpen] =
    useState(false);

  const intl = useIntl();

  const addressInfo = userData?.address;

  const currentAddress = addressInfo
    ? `[${changeEmptyValue(addressInfo?.postal_code)}] ${changeEmptyValue(addressInfo?.country)}, ${changeEmptyValue(addressInfo?.locality)}, ${changeEmptyValue(addressInfo?.region)}, ${changeEmptyValue(addressInfo?.street_address)}, ${changeEmptyValue(userData?.streetAddressLine2)}`
    : "-";

  const preferenceArr = [
    {
      id: 1,
      label: intl.formatMessage({
        id: "lang-name",
      }),
      value: `${changeEmptyValue(userData?.given_name)} ${changeEmptyValue(userData?.family_name)}`,
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: "lang-date-of-birth-label",
      }),
      value: `${changeEmptyValue(userData?.date_of_birth)}`,
    },
    {
      id: 3,
      label: intl.formatMessage({
        id: "lang-email-label",
      }),
      value: `${changeEmptyValue(userData?.identifiers?.email)}`,
    },
    {
      id: 4,
      label: intl.formatMessage({
        id: "lang-password-label",
      }),
      dialogName: "openChangePass",
      value: "*********",
      edit: true,
      modalName: "passwordChange",
    },
    {
      id: 5,
      label: `${intl.formatMessage({
        id: "lang-phone",
      })}`,
      value: `${changeEmptyValue(userData?.identifiers?.phone)}`,
    },
    {
      id: 6,
      label: intl.formatMessage({
        id: "lang-address",
      }),
      value: `${currentAddress}`,
    },
    {
      id: 7,
      label: intl.formatMessage({
        id: "lang-mail-opt-label",
      }),
      value: marketingConsent?.accepted ? <YesText /> : <NoText />,
      dialogName: "openChangeConsentMarketing",
      edit: true,
      modalName: "marketingConsent",
    },
    {
      id: 7,
      label: intl.formatMessage({
        id: "lang-mail-use-label",
      }),
      value: personalConsent?.accepted ? <YesText /> : <NoText />,
      dialogName: "openChangeConsentPersonal",
      edit: true,
      modalName: "personalConsent",
    },
  ];

  const openChangePass = useCallback(() => {
    setChangePasswordModalOpen(true);
  }, []);

  const openChangeConsentMarketing = useCallback(() => {
    setChangeConsentMarketingModalOpen(true);
  }, [setChangeConsentMarketingModalOpen]);

  const openChangeConsentPersonal = useCallback(() => {
    setChangeConsentPersonalModalOpen(true);
  }, [setChangeConsentPersonalModalOpen]);

  // Map of dialog names to actual functions
  const functionMap = {
    openChangePass,
    openChangeConsentMarketing,
    openChangeConsentPersonal,

    // Add other functions as needed
  };

  const handleDialogName = (dialogName, customData = "") => {
    if (typeof functionMap[dialogName] === "function") {
      functionMap[dialogName](customData);
    } else {
      console.error(`${dialogName} is not a valid function`);
    }
  };

  return (
    <>
      <ChangeMarketingModal
        openModal={changeConsentMarketingModalOpen}
        setOpenModal={setChangeConsentMarketingModalOpen}
        userData={userData}
        profileConsent={marketingConsent}
      />

      <ChangePersonalModal
        openModal={changeConsentPersonalModalOpen}
        setOpenModal={setChangeConsentPersonalModalOpen}
        userData={userData}
        profileConsent={personalConsent}
      />

      <ChangePasswordModal
        email={userData?.identifiers?.email}
        userId={userData?.user_id}
        openModal={changePasswordModalOpen}
        setOpenModal={setChangePasswordModalOpen}
      />

      {preferenceArr.map((preference) => {
        return (
          <div key={preference.label}>
            <CommonDescFive>{preference.label}</CommonDescFive>

            <MyProfileAccountValue>
              <p>{preference.value}</p>

              {preference.edit && (
                <MyProfileAccountEditPassword
                  onClick={() =>
                    handleDialogName(
                      preference.dialogName,
                      preference.modalname,
                    )
                  }
                >
                  <EditText />
                </MyProfileAccountEditPassword>
              )}
            </MyProfileAccountValue>
          </div>
        );
      })}
    </>
  );
};

export default AccountBasicInfo;
