import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { BorderButton, SecondaryButton } from "@components/buttons";
import { RoomReservationModal } from "@components/modal/modal-list";
import { DangerouslyHtml } from "@components/item";
import { ListWithTabs } from "@components/list";
import { SliderOne } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import {
  BookNowText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  useGetStayTowerPreview,
  useGetTowerRooms,
} from "@utils/api-requests/StayRequests";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import {
  LOCAL_STORAGE_LANGUAGE,
  SCROLL_INTO_VIEW_TIMER,
} from "@utils/api-requests/static-data";

import {
  TowerPreviewButtonWrapper,
  TowerPreviewDesc,
  TowerPreviewInfo,
} from "./StayStyling";
import { GAClickEvent } from "../../ga/GAConfiguration";

const TowerPreview = () => {
  const towerPreviewRef = useRef();
  const navigate = useNavigate();

  const {
    data: stayTowerPreview,
    isLoading,
    isError,
    error,
  } = useGetStayTowerPreview();

  const search = useLocation().search;

  const [filterType, setFilterType] = useState(
    search !== "" ? getSearchValue(search) : "",
  );

  const [openModal, setOpenModal] = useState(false);

  const [swiper, setSwiper] = useState();
  const [slideIndex, setSlideIndex] = useState(0);

  const {
    data: towerRooms,
    isFetching: roomsIsFetching,
    isError: roomsIsError,
    error: roomsError,
  } = useGetTowerRooms(filterType);

  const getTowerRoom = useCallback(
    (categoryId) => {
      setFilterType(categoryId);

      setSlideIndex(0);

      if (!swiper?.destroyed) {
        swiper?.slideToLoop(0, 0);
      }
    },
    [swiper],
  );

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (!isLoading && search !== "" && !search.includes("amenities")) {
        scrollToTargetAdjusted(towerPreviewRef);
      } else {
        setFilterType(stayTowerPreview?.[0]?.category_id);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [isLoading, search, stayTowerPreview]);

  return (
    <>
      <RoomReservationModal openModal={openModal} setOpenModal={setOpenModal} />

      <div ref={towerPreviewRef}>
        <ListWithTabs
          tabArr={stayTowerPreview}
          filterType={filterType}
          setFilterType={getTowerRoom}
          isLoading={isLoading}
          isError={isError}
          error={error}
        >
          <TowerPreviewDesc>
            <DangerouslyHtml
              value={
                stayTowerPreview?.filter(
                  (el) => el.category_id === filterType,
                )[0]?.full_description
              }
            />
          </TowerPreviewDesc>

          <ErrorManage
            isFetching={roomsIsFetching}
            isError={roomsIsError}
            error={roomsError}
            noLoading
          >
            <SliderOne
              data={towerRooms}
              swiper={swiper}
              setSwiper={setSwiper}
              slideIndex={slideIndex}
              setSlideIndex={setSlideIndex}
              imageFieldName="field_thumbnail"
            >
              <TowerPreviewInfo>
                <CommonTitleOne>
                  <DangerouslyHtml
                    value={towerRooms?.[slideIndex]?.field_room_name}
                  />
                </CommonTitleOne>

                <CommonDescTwo>
                  <DangerouslyHtml
                    className="ellipsis-6"
                    value={towerRooms?.[slideIndex]?.field_short_description}
                  />
                </CommonDescTwo>
              </TowerPreviewInfo>

              {towerRooms?.[slideIndex]?.field_display_details === "True" && (
                <TowerPreviewButtonWrapper>
                  <SecondaryButton
                    buttonColor="white"
                    noArrow
                    buttonEvent={() => {
                      GAClickEvent("click_event", {
                        action: "room",
                        category: "stay",
                        label: "booknow",
                        product:
                          towerRooms?.[slideIndex]?.enTitle?.toLowerCase(),
                      });

                      setOpenModal(true);
                    }}
                  >
                    <BookNowText />
                  </SecondaryButton>

                  <BorderButton
                    buttonEvent={() => {
                      GAClickEvent("click_event", {
                        action: "room",
                        category: "stay",
                        label: "viewdetails",
                        product: towerRooms?.[slideIndex]?.enTitle,
                      });

                      navigate(
                        `/${lang}/stay/room/${filterType.replaceAll(" ", "-")}${
                          towerRooms?.[slideIndex]?.url_alias
                        }`,
                      );
                    }}
                  >
                    <ViewDetailsText />
                  </BorderButton>
                </TowerPreviewButtonWrapper>
              )}
            </SliderOne>
          </ErrorManage>
        </ListWithTabs>
      </div>
    </>
  );
};

export default TowerPreview;
