import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { DangerouslyHtml } from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { useGetFooterQuery } from "@utils/api-requests/GlobalRequests";
import {
  ConnectWithUsText,
  FaxText,
  HotelReservationsText,
  PhoneText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { IsDesktop } from "@utils/media-query/mediaQuery";
import { useCheckChinese } from "@utils/lanugauge-settings/LanguageOptions";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  Address,
  ContactItem,
  ContactList,
  CopyRight,
  FooterBottom,
  FooterBottomWrapper,
  FooterBusinessNumber,
  FooterContainer,
  FooterHotelSection,
  FooterLink,
  FooterLogo,
  FooterLogoWrapper,
  FooterNav,
  FooterTop,
  FooterTopLeft,
  FooterTopRight,
  LinkGroup,
  MenuTitle,
  MenuWrapper,
  SectionHeader,
  SnsWrapper,
} from "./FooterStyling";
import { GAClickEvent } from "../../ga/GAConfiguration";
import { removeEmptySpace } from "@utils/modify-data/modifyData";

const Footer = () => {
  const url = useLocation().pathname;

  const isChinese = useCheckChinese();

  const { footerAddress, footerMenu, footerContact, footerSns } =
    useGetFooterQuery();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  // const openHandler = useCallback(() => {
  //   setOpenModal(true);
  // }, []);

  return (
    <FooterContainer
      noMargin={
        url === `${baseUrl}/momentum/sign-in` ||
        url === `${baseUrl}/momentum/join-momentum` ||
        url === `${baseUrl}/events/winter_wonderland_2024` ||
        url === `${baseUrl}/events/winter_wonderland_2025`
      }
    >
      {/* <UnauthorizedEmailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}

      <CommonContainer>
        <FooterTop>
          <FooterTopLeft>
            {footerAddress?.field_footerlogo && (
              <FooterLogoWrapper>
                <FooterLogo
                  data={`${process.env.REACT_APP_SERVER_URL}${footerAddress?.field_footerlogo[0]?.src}`}
                  alt={footerAddress.field_footerlogo[0]?.alt}
                />
              </FooterLogoWrapper>
            )}
          </FooterTopLeft>

          <FooterTopRight>
            <section>
              {footerMenu?.length > 0 && (
                <MenuWrapper key={footerMenu?.[0]?.entity_id}>
                  <MenuTitle to={`${baseUrl}${footerMenu?.[0]?.url}`}>
                    <DangerouslyHtml value={footerMenu?.[0]?.title} />
                  </MenuTitle>

                  <FooterNav>
                    {footerMenu?.[0]?.children.map((link) => {
                      return (
                        <FooterLink
                          to={`${baseUrl}${link.url}`}
                          key={link.entity_id}
                          onClick={() => {
                            GAClickEvent("click_event", {
                              action: "info",
                              category: "footer",
                              label: removeEmptySpace(link.enTitle),
                            });
                          }}
                        >
                          <DangerouslyHtml value={link.title} />
                        </FooterLink>
                      );
                    })}
                  </FooterNav>
                </MenuWrapper>
              )}
            </section>

            <section className="center">
              {footerMenu?.length > 0 && (
                <MenuWrapper key={footerMenu?.[1].entity_id}>
                  <FooterNav>
                    {footerMenu?.[1]?.children.map((link) => {
                      return (
                        <FooterLink
                          to={`${baseUrl}${link.url}`}
                          key={link.entity_id}
                          onClick={() => {
                            GAClickEvent("click_event", {
                              action: "info",
                              category: "footer",
                              label: removeEmptySpace(link.enTitle),
                            });
                          }}
                        >
                          <DangerouslyHtml value={link.title} />
                        </FooterLink>
                      );
                    })}
                  </FooterNav>
                </MenuWrapper>
              )}
            </section>

            <FooterHotelSection>
              <IsDesktop>
                <SectionHeader>
                  <HotelReservationsText />
                </SectionHeader>
              </IsDesktop>

              <Address>
                <div>
                  <DangerouslyHtml
                    value={footerAddress?.field_address_line_1}
                  />
                </div>

                <div>
                  <DangerouslyHtml
                    value={footerAddress?.field_address_line_2}
                  />
                </div>
              </Address>

              <ContactList>
                <ContactItem>
                  <span>
                    <PhoneText />
                  </span>

                  <a href={`tel:${footerContact?.field_phone_wd}`}>
                    {footerContact?.field_phone_wd}
                  </a>
                </ContactItem>

                <ContactItem>
                  <span>
                    <FaxText />
                  </span>

                  <a href={`tel:${footerContact?.field_phone_wd}`}>
                    {footerContact?.field_fax_wd}
                  </a>
                </ContactItem>

                {/* <ContactItem>
                  <span>
                    <RoomReservationText />
                  </span>

                  <a href={`tel:+${footerContact?.field_room_reservation_wd}`}>
                    {footerContact?.field_room_reservation_wd}
                  </a>
                </ContactItem> */}

                <a
                  href={`mailto:${footerContact?.field_email_wd}`}
                  style={{ marginTop: "2rem" }}
                >
                  {footerContact?.field_email_wd}
                </a>
              </ContactList>

              {localStorage.getItem(LOCAL_STORAGE_LANGUAGE) === "ko" && (
                <FooterBusinessNumber>
                  <p>(주)인스파이어인티그레이티드리조트</p>

                  <p>사업자등록번호 : 496-86-00049</p>

                  <p>통신판매신고번호 : 2023-인천중구-0804</p>

                  <p>대표이사 : 첸시</p>
                </FooterBusinessNumber>
              )}
            </FooterHotelSection>
          </FooterTopRight>
        </FooterTop>
      </CommonContainer>

      <FooterBottom>
        <CommonContainer>
          <FooterBottomWrapper>
            <section>
              <CopyRight>
                <DangerouslyHtml
                  value={footerAddress?.field_copyright_footer}
                />
              </CopyRight>
            </section>

            {!isChinese && (
              <SnsWrapper>
                <IsDesktop>
                  <b>
                    <ConnectWithUsText />
                  </b>
                </IsDesktop>

                <LinkGroup>
                  {footerSns?.field_facebook_connection !== "" && (
                    <li>
                      <a
                        href={footerSns?.field_facebook_connection}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {footerSns?.field_facebook_connection_logo && (
                          <object
                            data={`${process.env.REACT_APP_SERVER_URL}${footerSns?.field_facebook_connection_logo[0]?.src}`}
                            aria-labelledby={
                              footerSns.field_facebook_connection_logo[0]?.alt
                            }
                          />
                        )}
                      </a>
                    </li>
                  )}

                  {footerSns?.field_instagram !== "" && (
                    <li>
                      <a
                        href={footerSns?.field_instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {footerSns?.field_instagram_connection_logo && (
                          <object
                            type="image/svg+xml"
                            loading="lazy"
                            data={`${process.env.REACT_APP_SERVER_URL}${footerSns?.field_instagram_connection_logo[0]?.src}`}
                            aria-labelledby={
                              footerSns.field_instagram_connection_logo[0]?.alt
                            }
                            alt={
                              footerSns.field_instagram_connection_logo[0]?.alt
                            }
                          />
                        )}
                      </a>
                    </li>
                  )}

                  {footerSns?.field_kakao_connection !== "" && (
                    <li>
                      <a
                        href={footerSns?.field_kakao_connection}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {footerSns?.field_kakao_connection_logo && (
                          <object
                            type="image/svg+xml"
                            data={`${process.env.REACT_APP_SERVER_URL}${footerSns?.field_kakao_connection_logo[0]?.src}`}
                            aria-labelledby={
                              footerSns.field_kakao_connection_logo[0]?.alt
                            }
                          />
                        )}
                      </a>
                    </li>
                  )}

                  {footerSns?.field_wechat_connection !== "" && (
                    <li>
                      <a
                        href={footerSns?.field_wechat_connection}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {footerSns?.field_wechat_connection_logo && (
                          <object
                            type="image/svg+xml"
                            data={`${process.env.REACT_APP_SERVER_URL}${footerSns?.field_wechat_connection_logo[0]?.src}`}
                            aria-labelledby={
                              footerSns.field_wechat_connection_logo[0]?.alt
                            }
                          />
                        )}
                      </a>
                    </li>
                  )}

                  {footerSns?.field_naver_connection !== "" && (
                    <li>
                      <a
                        href={footerSns?.field_naver_connection}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {footerSns?.field_naver_connection_logo && (
                          <object
                            type="image/svg+xml"
                            data={`${process.env.REACT_APP_SERVER_URL}${footerSns?.field_naver_connection_logo[0]?.src}`}
                            aria-labelledby={
                              footerSns.field_naver_connection_logo[0]?.alt
                            }
                          />
                        )}
                      </a>
                    </li>
                  )}
                </LinkGroup>
              </SnsWrapper>
            )}
          </FooterBottomWrapper>
        </CommonContainer>
      </FooterBottom>
    </FooterContainer>
  );
};

export default memo(Footer);
